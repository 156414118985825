import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import queryString from "query-string";

// seo
import Seo from "../components/Seo";

// config
import config from "../config";

// app header
// import AppHeader from "../components/AppHeader/AppHeader";

// Api Action
import { createUserDetailDoctorConsult } from "../actions/doctorReferralActions";

// services
import { sendToCT } from "../services/Clevertap";

// component
import AppointmentDetails from "../components/DoctorConsult/AppointmentDetails/AppointmentDetails";
import Header from "../components/DoctorConsult/Header/header";
import Share from "../components/Share/Share";
// import TwinAppWebinarPaymentHeader from "../components/Webinar/TwinAppWebinarPaymentHeader";
import Button from "../components/Button/Button";

// css
import * as styles from "./doctor-consult.module.css";

const DoctorConsultSuccessfulPage = props => {
  const [successData, setSuccessData] = useState({});
  const [doctorDetails, setDoctorDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const { orderId, addToC, appHeader, twinApp, clientId } = queryString.parse(
    props.location.search
  );

  // Define a ref to keep track of whether the API call(createUserDetailDoctorConsult) has been made
  const userApiCalledRef = useRef(false);

  // Function:- To save user data to dx-backend table called doctor consult
  useEffect(() => {
    const handleUserDataSaveDoctorConsult = async () => {
      if (!successData || userApiCalledRef.current) {
        return;
      }

      const {
        name,
        email,
        phone,
        doctorId,
        doctorName,
        schedulerStartTime,
        meetingLink,
        couponCode,
        amount,
        transactionId,
      } = successData;

      if (
        !name ||
        !email ||
        !phone ||
        !doctorId ||
        !doctorName ||
        !schedulerStartTime
      ) {
        console.error("Error: Fields are missing in successData");
        return;
      }

      const doctorConsultBody = {
        indiaClinicId: clientId,
        name: name,
        email: email,
        phone: phone,
        doctorId: doctorId,
        doctorName: doctorName,
        acuityId: successData.Doctor.calendarId,
        consultationDateTime: schedulerStartTime,
        language: successData.Doctor.language,
        zoomLink: meetingLink,
        couponCode: couponCode,
        costPrice: amount,
        razorpayPaymentId: transactionId,
        leadSource:
          appHeader && appHeader === "mobile" ? "TwinApp" : "TwinWebsite",
      };

      try {
        await createUserDetailDoctorConsult(doctorConsultBody);
        // Update the ref to indicate that the API call has been made
        userApiCalledRef.current = true;
      } catch (error) {
        console.error("Error in user detail doctor consult func", error);
      }
    };

    handleUserDataSaveDoctorConsult(); // Function Call
  }, [successData]);

  // --------> GET APPOINTMENT DETAILS <---------
  useEffect(() => {
    const getSuccessDetails = async () => {
      const successOptions = {
        method: "get",
        url:
          config.DX_API_BASE_URL +
          "/api/doctor/" +
          orderId +
          `/appointment/?include=%5B"Doctors"%5D`,
      };

      try {
        setLoading(true);
        const response = await axios(successOptions);

        // clevertap event
        if (twinApp && twinApp === "yes") {
          // For Twin App
          if (window.twinApp) {
            const ctData = JSON.stringify({
              action: "cleverTapEvent",
              arguments: {
                event: "doctor_consult_payment_successful",
                payload: { action: "Doctor_Consult_Payment_Successful" },
              },
            });
            window.twinApp.postMessage(ctData);
          }
        } else {
          // For Web
          sendToCT(`doctor_consult_payment_successful`, {
            action: "Doctor_Consult_Payment_Successful",
          });
        }

        if (response.data.data.status === "SUCCESS") {
          setSuccessData(response.data.data);
          setDoctorDetails(response.data.data.Doctor);
          setLoading(false);
        } else {
          setLoading(true);
        }
      } catch (error) {
        console.log("Error while fetching doctor success details", error);
      }
    };

    getSuccessDetails();

    // I am calling this function inside setInterval -> to get the meeting link
    let nIntervId = setInterval(getSuccessDetails, 5000);

    // stop setInterval function
    setTimeout(() => {
      return clearInterval(nIntervId);
    }, 6000);
  }, [orderId, twinApp]);

  const handleTwinAppRedirection = () => {
    if (window.twinApp) {
      console.log("==>> present in window twinApp");
      let data = JSON.stringify({ action: "dx_home" });
      window.twinApp.postMessage(data);
    } else if (window.webkit) {
      console.log("==>> present in window");
      window.webkit.messageHandlers.twinApp.postMessage({
        action: "dx_home",
      });
    } else {
      console.log("Back from doctor-consult page");
    }
  };

  return (
    <div className={`bgLightGreen ${styles.doctorConsult_successPage}`}>
      <Seo
        title="Reverse Diabetes @ Twin | Book an appointment with India's leading Doctors"
        description="Is Diabetes Reversal possible? Book a Personalised Video Consultation with Top Diabetologists & get all your Diabetes-related queries answered."
      />

      {/* Header -> App for Dx */}
      {/* {appHeader === "mobile" && (
        <AppHeader text="DOCTOR CONSULT" isBack={true} />
      )} */}

      {/* Handle header for web and App for dx */}
      {twinApp && twinApp === "yes" ? null : <Header appHeader={appHeader} />}
      {/* {twinApp && twinApp === "yes" ? (
        <TwinAppWebinarPaymentHeader text="Back to home page" />
      ) : appHeader && appHeader === "no" ? null : (
        <Header appHeader={appHeader} />
      )} */}

      {loading ? (
        <div className="d-block text-center">
          <Spinner animation="grow" role="status" />
        </div>
      ) : (
        <div className={`container twinContainer ${styles.docSuccss_sec}`}>
          {/* success message */}
          <div className="d-flex pt-3 pb-4 align-items-center">
            {/* tick-icon */}
            <div className={styles.docSucc_tickIcn}>
              <span className={`icon-yes`} aria-hidden="true"></span>
            </div>
            {/* text */}
            <div className={styles.docSucc_sccTxt}>
              <p>Appointment booked!</p>
            </div>
          </div>
          {/* Line - Desktop */}
          <div className={styles.docSucc_desk_line}></div>
          {/* Details */}
          <div className="row">
            {/* Appointment Details  */}
            <div className="col-12 col-xs-12 col-md-12 col-lg-6">
              <AppointmentDetails
                doctorDetails={doctorDetails}
                successData={successData}
                orderId={orderId}
                addToC={addToC}
                loading={loading}
              />
            </div>
            {/* Twin App Cta Redirection to Twin App */}
            {twinApp && twinApp === "yes" ? (
              <div className="col-12 text-center mt-4">
                <Button
                  type="button"
                  text="Go to home"
                  onClick={handleTwinAppRedirection}
                  btnClass={styles.twinAppBtn}
                />
              </div>
            ) : null}
            {/* Appointment Content */}
            <div
              className={`col-12 col-xs-12 col-md-12 col-lg-6 ${styles.docSucc_appntmntCntSec}`}
            >
              {/* Offer */}
              <div className={`d-flex ${styles.docSucc_appntmntCnt}`}>
                <span
                  className={`mediumIcon icon-09`}
                  aria-hidden="true"
                  style={{ color: "#3E928B" }}
                ></span>
                <p>
                  Get ₹2,000 worth of exclusive offers from the Twin program
                </p>
              </div>
              {/* Line - Desktop */}
              <div className={styles.docSucc_desk_line_2}></div>
              {/* Refer Text */}
              <div className={styles.docSucc_appntmntReferTxt}>
                <h3>
                  Help your friends and family beat diabetes, {successData.name}
                </h3>
                <div className={styles.docSucc_referTxt_cnt}>
                  <p>
                    Invite them to join the Twin family and live a medicine-free
                    life.
                  </p>
                </div>
                {/* Copy Link Component */}
                <div className={styles.docSucc_shareLnk}>
                  <Share link="/doctor-consult" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DoctorConsultSuccessfulPage;
